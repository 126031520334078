<template>
    <div id="display-liquid-level-component">
        <div :id="getDisplayLiquidLevelData('name') + '-liquid-level-component'">
            <v-img
                :src=" $vuetify.theme.dark ?
                    '/images/asset/dark/' + getDisplayLiquidLevelData('image_path') + '.png' :
                    '/images/asset/light/' + getDisplayLiquidLevelData('image_path') + '.png'"
                :height="getDisplayLiquidLevelData('image_height')"
                aspect-ratio="1" contain
            />

            <div :class="getDisplayLiquidLevelData('name') + '-holder'">
                <!--------------------
                    LEFT INDICATOR
                --------------------->
                <div v-if="show_indicator" :class="getDisplayLiquidLevelData('name') + '-graduated-liquid-indicator'">
                    <high-graduate-liquid-indicator-svg
                        v-if="getDisplayLiquidLevelData('high_graduate')"
                        :size="getDisplayLiquidLevelData('graduate_height')"
                        :class="getDisplayLiquidLevelData('name') + '-graduate'"
                        :id_tank="id_asset"
                    />

                    <graduated-liquid-indicator-svg
                        v-else
                        :size="getDisplayLiquidLevelData('graduate_height')"
                        :class="getDisplayLiquidLevelData('name') + '-graduate'"
                        :id_tank="id_asset"
                    />

                    <div :id="getDisplayLiquidLevelData('name') + '-graduated-liquid-indicator-dash-' + id_asset"
                         :class="getDisplayLiquidLevelData('name') + '-graduated-liquid-indicator-dash'"
                    />

                    <div :class="getDisplayLiquidLevelData('name') + '-alert red--text font-12 font-weight-bold'">
                        <div :class="getDisplayLiquidLevelData('name') + '-alert-label'" :id="getDisplayLiquidLevelData('name') + '-alert-' + id_asset"></div>
                    </div>
                </div>

                <!-------------------
                    LIQUID LEVEL
                -------------------->
                <div :class="getDisplayLiquidLevelData('name') + '-shape'">
                    <div :id="'volume-liquid-border-' + id_asset"
                         class="volume-liquid-border"
                    />
                    <div :id="'volume-liquid-' + id_asset" class="volume-liquid"/>
                </div>

                <!--------------------
                    RIGHT INDICATOR
                ---------------------->
                <div
                    v-if="show_indicator"
                    :id="getDisplayLiquidLevelData('name') + '-volume-indicator-' + id_asset"
                    :class="getDisplayLiquidLevelData('name') + '-volume-indicator'"
                >
                    <div :id="'triangle-' + id_asset" class="triangle"/>

                    <v-chip :color="chipsColor" class="px-1" label small>
                        <span class="white--text">{{ numberWithSpaces(Math.round(actual_volume)) }} L</span>
                    </v-chip>
                </div>

                <div v-if="$router.currentRoute.name.includes('orders_cockpit')">
                    <v-btn
                        :class="(getDisplayLiquidLevelData('high_graduate') ? 'alert-silo-order' : (getDisplayLiquidLevelData('name') === 'ibc' ? 'alert-ibc-order': 'alert-tank-order')) + ' unsolicited-btn bounce-btn'"
                        color="error"
                        elevation="0"
                        fab
                        small
                    >
                        <v-icon class="color-white-fix">mdi-tanker-truck</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HighGraduateLiquidIndicatorSvg from "@/components/Svg/HighGraduateLiquidIndicatorSvg";
import GraduatedLiquidIndicatorSvg from "@/components/Svg/GraduatedLiquidIndicatorSvg";

export default {
    name: 'DisplayLiquidLevelComponentV2',
    components: {GraduatedLiquidIndicatorSvg, HighGraduateLiquidIndicatorSvg},
    mounted() {
        setTimeout(() => {
            this.prepareSetVolumeForLiquidComponent();
        }, 20)
    },
    props: {
        volume_total: {
            type: Number,
            required: true
        },
        actual_volume: {
            type: Number,
            required: false,
            default: 0
        },
        id_asset: {
            type: Number,
            required: true
        },
        show_indicator: {
            type: Boolean,
            required: true
        },
        type_asset: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            chipsColor: '#c6d368',
            display_liquid_level: {
                0: {
                    name: 'tank',
                    image_path: 'tank',
                    image_height: 100,
                    high_graduate: false,
                    graduate_height: 75,
                    volume_adjustment: 75,
                    indicator_adjustment: 75,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 70
                },
                1: {
                    name: 'tank-cylindrical',
                    image_path: 'tank_cylindrical',
                    image_height: 180,
                    high_graduate: true,
                    graduate_height: 168,
                    volume_adjustment: 169,
                    indicator_adjustment: 169,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 162
                },
                2: {
                    name: 'tank-rectangular',
                    image_path: 'tank_rectangular',
                    image_height: 100,
                    high_graduate: false,
                    graduate_height: 75,
                    volume_adjustment: 75,
                    indicator_adjustment: 75,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 70
                },
                3: {
                    name: 'ibc',
                    image_path: 'IBC',
                    image_height: 120,
                    high_graduate: false,
                    graduate_height: 100,
                    volume_adjustment: 103,
                    indicator_adjustment: 100,
                    indicator_adjustment_2: 3,
                    dash_adjustment: 94
                },
                4: {
                    name: 'silo-rectangular',
                    image_path: 'silo_rectangular',
                    image_height: 180,
                    high_graduate: true,
                    graduate_height: 168,
                    volume_adjustment: 172,
                    indicator_adjustment: 165,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 162
                },
                5: {
                    name: 'silo',
                    image_path: 'silo',
                    image_height: 180,
                    high_graduate: true,
                    graduate_height: 168,
                    volume_adjustment: 175,
                    indicator_adjustment: 170,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 162
                },
                6: {
                    name: 'tower-rectangular',
                    image_path: 'tower_rectangular',
                    image_height: 180,
                    high_graduate: true,
                    graduate_height: 168,
                    volume_adjustment: 170,
                    indicator_adjustment: 165,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 162
                },
                7: {
                    name: 'tower-cylindrical',
                    image_path: 'tower_cylindrical',
                    image_height: 180,
                    high_graduate: true,
                    graduate_height: 168,
                    volume_adjustment: 170,
                    indicator_adjustment: 165,
                    indicator_adjustment_2: 10,
                    dash_adjustment: 162
                },
            }
        }
    },
    methods: {
        getDisplayLiquidLevelData(data) {
            return this.display_liquid_level[this.type_asset >= 0 && this.type_asset <= 7 ? this.type_asset : 0][data];
        },

        prepareSetVolumeForLiquidComponent() {
            this.chipsColor = this.setVolumeForLiquidComponent(
                this.display_liquid_level[this.type_asset >= 0 && this.type_asset <= 7 ? this.type_asset : 0].name,
                this.show_indicator,
                this.id_asset,
                this.volume_total,
                this.actual_volume,
                this.getDisplayLiquidLevelData('volume_adjustment'),
                this.getDisplayLiquidLevelData('indicator_adjustment'),
                this.getDisplayLiquidLevelData('indicator_adjustment_2'),
                this.getDisplayLiquidLevelData('dash_adjustment')
            );
        },

        setVolumeForLiquidComponent(type, show_indicator, id_tank, volume_total, actual_volume, volume_adjustment, indicator_adjustment, indicator_adjustment_2, dash_adjustment) {
            let volume = document.getElementById('volume-liquid-' + id_tank);
            volume.style.height = ((actual_volume * volume_adjustment) / volume_total) + "px";
            volume.style.background = 'var(--v-primary-base)';
            //volume.style.background = 'linear-gradient(var(--v-primary-base), var(--v-white-base))';
            volume.style.opacity = '0.3';

            let volume_border = document.getElementById('volume-liquid-border-' + id_tank);
            volume_border.style.bottom = (((actual_volume * volume_adjustment) / volume_total) - 2) + "px";
            volume_border.style.background = 'var(--v-primary-base)';
            volume_border.style.opacity = '0.8';

            let chipsColor = null;
            if (show_indicator) {
                let indicator = document.getElementById(type + '-volume-indicator-' + id_tank);
                let triangle = document.getElementById('triangle-' + id_tank);
                let alert_indicator = document.getElementById('alert-indicator' + id_tank);
                let alert_label = document.getElementById(type + '-alert-' + id_tank);

                indicator.style.bottom = ((actual_volume * indicator_adjustment) / volume_total) - indicator_adjustment_2 + "px";
                chipsColor = this.$vuetify.theme.themes.light.primary;
                triangle.style.borderRight = "7px solid " + this.$vuetify.theme.themes.light.primary;

                let alerts = this.$store.getters['alerts/alertsByDevice'](this.$store.getters['tanks/getTankById'](id_tank).devices[0].id_device);
                let reference_size_graduate = this.display_liquid_level[this.type_asset >= 0 && this.type_asset <= 7 ? this.type_asset : 0].high_graduate ? 205 : 105;
                alerts.forEach(alert => {
                    alert.configuration.forEach(configuration => {
                        if (configuration.id_metric === 32 && configuration.type === "limit") {
                            alert_indicator.y1.baseVal.value = (reference_size_graduate - parseInt(configuration.limit * reference_size_graduate / volume_total)) > 5 ? reference_size_graduate - parseInt(configuration.limit * reference_size_graduate / volume_total) : 5;

                            if (alert.triggered) {
                                chipsColor = this.$vuetify.theme.themes.light.error;
                                triangle.style.borderRight = "7px solid " + this.$vuetify.theme.themes.light.error;
                            }
                            alert_label.innerHTML = configuration.operator + ' ' + parseInt((configuration.limit * 100) / volume_total) + '%';
                        }
                    });
                });

                let dash = document.getElementById(type + '-graduated-liquid-indicator-dash-' + id_tank);
                dash.style.bottom = ((actual_volume * dash_adjustment) / volume_total) + "px";
                dash.style.background = this.$vuetify.theme.themes.light.primary;
            }

            return chipsColor;
        }
    },
    watch: {
        actual_volume: function () {
            this.prepareSetVolumeForLiquidComponent();
        }
    }
}
</script>

<style>
.alert-silo-order {
    position: absolute !important;
    left: -10px !important;
    top: -5px !important;;
}

.alert-tank-order {
    position: absolute !important;
    left: -5px !important;
    top: 5px !important;
}

.volume-liquid {
    transition: height 1000ms;
    width: 170px;
    height: 0;
    position: absolute;
    bottom: 0;
}

.volume-liquid-border {
    height: 2px;
    background: white;
    transition: bottom 1000ms;
    width: 170px;
    position: absolute;
    bottom: 0;
}

.triangle {
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-right: 7px solid;
    position: absolute;
    left: -7px;
    top: 10px
}

/*-----------
    tank
------------*/
#tank-liquid-level-component {
    height: 100px;
}

.tank-holder {
    position: relative;
    bottom: 110px;
    height: 100px;
    width: 180px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.tank-shape {
    height: 75px;
    width: 170px;
    border-radius: 50px;
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;
}

.tank-volume-indicator {
    position: absolute;
    left: 190px;
    bottom: 0;
    transition: bottom 1000ms;
}

.tank-graduated-liquid-indicator {
    position: absolute;
    left: -25px;
    top: 22px;
}

.tank-graduated-liquid-indicator-dash {
    width: 20px;
    height: 2px;
    position: absolute;
    background: var(--v-primary-base);
    bottom: 0;
    transition: bottom 1000ms !important;
}

.tank-alert {
    position: absolute;
    left: 0;
    bottom: 0;
}

.tank-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.tank-graduate {
    height: 75px;
}
/*---------------------
    tank-cylindrical
---------------------*/
#tank-cylindrical-liquid-level-component {
    height: 180px;
}

.tank-cylindrical-holder {
    position: relative;
    height: 180px;
    width: 80px;
    bottom: 185px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.tank-cylindrical-shape {
    height: 169px;
    width: 75px;
    border-radius: 25px;
    position: absolute;
    left: 50.3%;
    transform: translate(-50%, 0);
    overflow: hidden;
    bottom: 3px;
}

.tank-cylindrical-volume-indicator {
    position: absolute;
    left: 95px;
    bottom: 0;
    transition: bottom 1000ms;
}

.tank-cylindrical-graduated-liquid-indicator {
    position: absolute;
    left: -45px;
    top: 9px;
}

.tank-cylindrical-graduated-liquid-indicator-dash {
    width: 35px;
    height: 3px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;
}

.tank-cylindrical-alert {
    position: absolute;
    left: 0;
    bottom: 10px;
}

.tank-cylindrical-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.tank-cylindrical-graduate {
    height: 168px;
}

.tank-cylindrical-graduate {
    height: 168px;
}

/*---------------------
    tank-rectangular
----------------------*/
#tank-rectangular-liquid-level-component {
    height: 100px;
}

.tank-rectangular-holder {
    position: relative;
    bottom: 110px;
    height: 100px;
    width: 180px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.tank-rectangular-shape {
    height: 75px;
    width: 170px;
    border-radius: 5px;
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;
}

.tank-rectangular-volume-indicator {
    position: absolute;
    left: 190px;
    bottom: 0;
    transition: bottom 1000ms;
}

.tank-rectangular-graduated-liquid-indicator {
    position: absolute;
    left: -25px;
    top: 22px;
}

.tank-rectangular-graduated-liquid-indicator-dash {
    width: 20px;
    height: 2px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;
}

.tank-rectangular-alert {
    position: absolute;
    left: 0;
    bottom: 0;
}

.tank-rectangular-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.tank-rectangular-graduate {
    height: 75px;
}

/*---------
    IBC
----------*/
#ibc-liquid-level-component {
    height: 120px;
}

.ibc-holder {
    position: relative;
    bottom: 110px;
    height: 110px;
    width: 124px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.ibc-shape {
    height: 103px;
    width: 114px;
    border-radius: 5px;
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;
}

.ibc-volume-indicator {
    position: absolute;
    left: 135px;
    bottom: 20px;
    transition: bottom 1000ms;
}

.ibc-graduated-liquid-indicator {
    height: 100px;
    position: absolute;
    left: -35px;
    top: 0px;
    padding-bottom: -5px;
}

.ibc-graduated-liquid-indicator-dash {
    width: 25px;
    height: 2px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;

}

.ibc-alert {
    position: absolute;
    left: 0;
    bottom: 0;
}

.ibc-alert-label {
    position: absolute;
    bottom: 3px;
    left: -37px;
}

.alert-ibc-order {
    position: absolute;
    left: -10px;
    top: -15px;
}

.ibc-graduate {
    height: 100px;
}

/*---------------------
    silo-rectangular
---------------------*/

#silo-rectangular-liquid-level-component {
    height: 180px;
}

.silo-rectangular-holder {
    position: relative;
    height: 180px;
    width: 80px;
    bottom: 185px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.silo-rectangular-shape {
    height: 172px;
    width: 77px;
    bottom: 0px;
    left: 50.3%;
    position: absolute;
    transform: translate(-50%, 0);
    overflow: hidden;
    clip-path: polygon(32% 0, 68% 0%, 100% 12%, 100% 78%, 68% 100%, 32% 100%, 0 78%, 0 12%);

}

.silo-rectangular-volume-indicator {
    position: absolute;
    left: 95px;
    bottom: 0;
    transition: bottom 1000ms;
}

.silo-rectangular-graduated-liquid-indicator {
    position: absolute;
    left: -45px;
    top: 9px;
}

.silo-rectangular-graduated-liquid-indicator-dash {
    width: 35px;
    height: 3px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;
}

.silo-rectangular-alert {
    position: absolute;
    left: 0;
    bottom: 10px;
}

.silo-rectangular-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.silo-rectangular-graduate {
    height: 168px;
}

/*---------------------
    tower-cylindrical
---------------------*/
#tower-cylindrical-liquid-level-component {
    height: 180px;
}

.tower-cylindrical-holder {
    position: relative;
    height: 180px;
    width: 80px;
    bottom: 185px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.tower-cylindrical-shape {
    height: 172px;
    width: 77px;
    bottom: 0px;
    left: 50.3%;
    position: absolute;
    transform: translate(-50%, 0);
    overflow: hidden;
    border-radius: 25px 25px 5px 5px;
}

.tower-cylindrical-volume-indicator {
    position: absolute;
    left: 95px;
    bottom: 0;
    transition: bottom 1000ms;
}

.tower-cylindrical-graduated-liquid-indicator {
    position: absolute;
    left: -45px;
    top: 9px;
}

.tower-cylindrical-graduated-liquid-indicator-dash {
    width: 35px;
    height: 3px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;
}

.tower-cylindrical-alert {
    position: absolute;
    left: 0;
    bottom: 10px;
}

.tower-cylindrical-alert-25 {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.tower-cylindrical-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.tower-cylindrical-graduate {
    height: 168px;
}

/*---------------------
    silo
---------------------*/
#silo-liquid-level-component {
    height: 180px;
}

.silo-holder {
    position: relative;
    height: 180px;
    width: 80px;
    bottom: 185px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.silo-shape {
    height: 172px;
    width: 77px;
    bottom: 0px;
    left: 50.3%;
    position: absolute;
    transform: translate(-50%, 0);
    overflow: hidden;
    border-radius: 50px;
    clip-path: polygon(32% 0, 68% 0%, 100% 12%, 100% 78%, 68% 100%, 32% 100%, 0 78%, 0 12%);
}

.silo-volume-indicator {
    position: absolute;
    left: 95px;
    bottom: 0;
    transition: bottom 1000ms;
}

.silo-graduated-liquid-indicator {
    position: absolute;
    left: -45px;
    top: 9px;
}

.silo-graduated-liquid-indicator-dash {
    width: 35px;
    height: 3px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;
}

.silo-alert {
    position: absolute;
    left: 0;
    bottom: 10px;
}

.silo-alert-25 {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.silo-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.silo-graduate {
    height: 168px;
}
/*---------------------
    tower-rectangular
---------------------*/
#tower-rectangular-liquid-level-component {
    height: 180px;
}

.tower-rectangular-holder {
    position: relative;
    height: 180px;
    width: 80px;
    bottom: 185px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.tower-rectangular-shape {
    height: 172px;
    width: 77px;
    bottom: 0px;
    left: 50.3%;
    position: absolute;
    transform: translate(-50%, 0);
    overflow: hidden;
    clip-path: polygon(32% 0, 68% 0%, 100% 10%, 100% 98%, 98% 100%, 2% 100%, 0 98%, 0 10%);
}

.tower-rectangular-volume-indicator {
    position: absolute;
    left: 95px;
    bottom: 0;
    transition: bottom 1000ms;
}

.tower-rectangular-graduated-liquid-indicator {
    position: absolute;
    left: -45px;
    top: 9px;
}

.tower-rectangular-graduated-liquid-indicator-dash {
    width: 35px;
    height: 3px;
    position: absolute;
    background: #c6d368;
    bottom: 0;
    transition: bottom 1000ms !important;
}

.tower-rectangular-alert {
    position: absolute;
    left: 0;
    bottom: 10px;
}

.tower-rectangular-alert-label {
    position: absolute;
    bottom: 0;
    left: -37px;
}

.tower-rectangular-graduate {
    height: 168px;
}
</style>